(function () {

	document.addEventListener('readystatechange', function (event) {
		// All HTML DOM elements are accessible
		if (event.target.readyState === "interactive") {}

    // Now external resources are loaded too, like css,src etc.
		if (event.target.readyState === "complete") {

      let listDudas = document.querySelectorAll('.uk-accodion-title-custom');

      for (let i = 0; i < listDudas.length; i++){

        listDudas[i].addEventListener("click", function (event){
          let linkacoordion = event.target;
          console.log(linkacoordion);
          if(!linkacoordion ) return;

          let contenicon = linkacoordion.querySelector(".uk-accodion-icon-custom_flechas");
          console.log(contenicon);
          if(!contenicon) return;
          let iconoabajo= contenicon.querySelector(".icono_custom_abajo");
          let iconoarriba= contenicon.querySelector(".icono_custom_arriba");
          console.log(iconoabajo, iconoarriba);




          for (let j = 0; j < listDudas.length; j++){
            let contenicontemp =  listDudas[j].querySelector(".uk-accodion-icon-custom_flechas");
            if(contenicontemp){
              let iconoabajotemp= contenicontemp.querySelector(".icono_custom_abajo");
              iconoabajotemp.classList.remove("uk-hidden");
              let iconoarribatemp= contenicontemp.querySelector(".icono_custom_arriba");
              iconoarribatemp.classList.add("uk-hidden");
            }
          }

          if(linkacoordion.ariaExpanded !== 'true'){
            iconoabajo.classList.add("uk-hidden");
            iconoarriba.classList.remove("uk-hidden");
          }

        });

      }

    }

	});
})();
