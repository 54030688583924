try {
	// Components
  require('./components/vendor/jquery');
  window.$ = jQuery;
  require('./components/vendor/uikit');

  require('./components/uikit/uk-accordion');

  //require('./components/custom/header-sticky');
} catch ( e ) { }
